import { Trans } from "@lingui/macro";
import cx from "classnames";
import { useMedia } from "react-use";

export const RulesTab = () => {
  const headers = [
    { main: null, sub: ["Level"] },
    {
      main: "Collateral and Trade Size",
      sub: ["Min Collateral In a Trade", "Collateral", "Max Collateral In a Trade"],
    },
    { main: "KPIs", sub: ["Min Trades", "Active Days", "Period", "Profit Share"] },
    { main: "Performance", sub: ["Promotion", "Maintain Funding"] },
  ];

  const data = [
    {
      level: "Qualification",
      minCollateralInTrade: "$100",
      collateral: "$400",
      maxCollateralInTrade: "$400",
      minTrades: "8",
      activeDays: "5",
      period: "7",
      profitShare: "100%",
      promotion: "115%",
      maintainFunding: "N/A",
    },
    {
      level: "Silver",
      minCollateralInTrade: "$500",
      collateral: "$2,000",
      maxCollateralInTrade: "$1,000",
      minTrades: "16",
      activeDays: "11",
      period: "15",
      profitShare: "80%",
      promotion: "125%",
      maintainFunding: "115%",
    },
    {
      level: "Gold",
      minCollateralInTrade: "$1,250",
      collateral: "$5,000",
      maxCollateralInTrade: "$2,500",
      minTrades: "24",
      activeDays: "16",
      period: "20",
      profitShare: "80%",
      promotion: "125%",
      maintainFunding: "115%",
    },
    {
      level: "Platinum",
      minCollateralInTrade: "$2,500",
      collateral: "$10,000",
      maxCollateralInTrade: "$5,000",
      minTrades: "30",
      activeDays: "20",
      period: "25",
      profitShare: "80%",
      promotion: "N/A",
      maintainFunding: "115%",
    },
  ];
  const isMobile = useMedia("(max-width: 1024px)");
  return (
    <div className="container mx-auto p-4 Tab-content text-inactive">
      {!isMobile && (
        <div className="overflow-x-auto rounded-[2rem] border border-border">
          <table className="min-w-full text-white border-collapse">
            <thead className="text-inactive">
              <tr className="bg-background-5-v2-only">
                {headers.map((header, idx) => (
                  <th
                    key={idx}
                    className={cx([
                      "px-[1.5rem] py-[1.2rem] text-center text-[1.3rem] border-b border-border",
                      idx !== headers.length - 1 && "border-r",
                    ])}
                    colSpan={header.sub ? header.sub.length : 1}
                    rowSpan={header.sub ? 1 : 2}
                  >
                    {header.main}
                  </th>
                ))}
              </tr>
              <tr className="bg-[rgba(16,_16,_20,_0.5)]">
                {headers.map((header, idx) =>
                  header.sub
                    ? header.sub.map((subHeader, subIdx) => (
                        <th
                          key={`${idx}-${subIdx}`}
                          className={cx([
                            "px-[1.5rem] py-[1.2rem] text-center text-[1.2rem] border-b border-border",
                            idx !== headers.length - 1 && "border-r",
                          ])}
                        >
                          {subHeader}
                        </th>
                      ))
                    : null
                )}
              </tr>
            </thead>
            <tbody className="rounded-b-[2rem] overflow-hidden">
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="p-[1.2rem] border-t border-r border-border">{row.level}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.minCollateralInTrade}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.collateral}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.maxCollateralInTrade}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.minTrades}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.activeDays}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.period}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.profitShare}</td>
                  <td className="p-[1.2rem] border-t border-r border-border text-center">{row.promotion}</td>
                  <td className="p-[1.2rem] border-t border-border text-center">{row.maintainFunding}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <p className="mt-[1.5rem]">
        <Trans>Progress through different FUNDED levels: Metrics for getting Promoted</Trans>
      </p>

      <div className="mt-[1.5rem]">
        <p className="text-white text-[1.6rem] mb-[0.5rem]">Qualifier Levels:</p>
        <ul className="list-disc flex flex-col gap-[0.5rem] pl-[2rem]">
          <li>
            <Trans>Below 100% collateral: Lose funding, no payout.</Trans>
          </li>
          <li>
            <Trans>100% to 115% collateral: Demotion, keep 80% of gains subject to KPI’s.</Trans>
          </li>
          <li>
            <Trans>115% to 125% collateral: Maintain funding, keep 80% of gains.</Trans>
          </li>
          <li>
            <Trans>Above 125% collateral: Promotion, unless at max funding.</Trans>
          </li>
        </ul>
      </div>
      <div className="mt-[1.5rem]">
        <p className="text-white text-[1.6rem] mb-[0.5rem]">
          <Trans>Non compliance of KPIs will result in demotion, regardless of your PnL.</Trans>
        </p>
        <ul className="list-disc flex flex-col gap-[0.5rem] pl-[2rem]">
          <li>
            <Trans>Traders will be unable to earn their 80% profit share, in the event KPIs are not met.</Trans>
          </li>
          <li>
            <Trans>
              A Trader in qualification, will keep 100% of their profits, even if KPIs are not met. Though they will not
              qualify for funding.
            </Trans>
          </li>
        </ul>
      </div>
    </div>
  );
};
