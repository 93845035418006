import "./FundedTrading.scss";
import { Trans, t } from "@lingui/macro";
import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { ReactComponent as ArrowSmall } from "img/ArrowSmall.svg";
import Foxify_Ranks_0 from "img/Foxify_Ranks_0.png";
import Foxify_Ranks_1 from "img/Foxify_Ranks_1.png";
import Foxify_Ranks_2 from "img/Foxify_Ranks_2.png";
import Foxify_Ranks_3 from "img/Foxify_Ranks_3.png";
import BluredEffect from "components/BluredEffect";
import FundedTradingStart from "components/Funded/FundedTradingStart";
import Tab from "../../components/Tab/Tab";
import { ChallengeTab } from "components/Funded/Tabs/Challenge/Challenge";
import { LeaderBoardTab } from "components/Funded/Tabs/LeaderBoard";
import { NFTStakingTab } from "components/Funded/Tabs/NFTStaking";
import { RulesTab } from "components/Funded/Tabs/Rules";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { useLocalStorageByChainId } from "lib/localStorage";
import { useFundedTrader } from "hooks/useFundedTrader";
import FundedTradingBenefits from "components/Funded/FundedTradingBenefits";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { formatBigAmount } from "lib/formatAmount";
import { USDC_DECIMALS } from "lib/constants";
import { getPalmSubgraph } from "lib/subgraph";
import { gql } from "@apollo/client";
import { BigNumber } from "ethers";
import useSWR from "swr";
import { useIsDevelopment } from "lib/useIsDevelopment";

const fundedLevels = {
  0: "Qualification",
  1: "Silver",
  2: "Gold",
  3: "Platinum",
};

const fundedImages = {
  0: Foxify_Ranks_0,
  1: Foxify_Ranks_1,
  2: Foxify_Ranks_2,
  3: Foxify_Ranks_3,
};

const Funded = ({ setPendingTxns, pendingTxns, isFundedCreated, traderContractAddress }) => {
  const [currentFundedLevel, setCurrentFundedLevel] = useState<string | null>(null);
  const [isCreateFundModalOpen, setIsCreateFundModalOpen] = useState(false);

  const { chainId } = useChainId();
  const { isActive, isActivating, account } = useWeb3React();
  const { resChallengeNumber, isLoading, isWhitelisted } = useFundedTrader();
  const { path } = useRouteMatch();
  const isDevelopment = useIsDevelopment();

  const LIST_SECTIONS = isDevelopment
    ? ["Challenge", "NFT Staking", "Leaderboard", "Rules"]
    : ["Challenge", "Leaderboard", "Rules"];

  const LIST_SECTIONS_LABELS = {
    Challenge: t`Challenge`,
    NFT_Staking: t`NFT Staking`,
    Leaderboard: t`Leaderboard`,
    Rules: t`Rules`,
  };

  useEffect(() => {
    if (resChallengeNumber) {
      const formattedLevelNumber = resChallengeNumber.toNumber();
      setCurrentFundedLevel(fundedLevels[formattedLevelNumber]);
    }
  }, [resChallengeNumber]);

  const { data: fundedHistoryItems } = useSWR(account ? ["fundedHistoryItems", chainId, account] : null, {
    fetcher: () => {
      const query = gql`
      {
        fundedHistoryItems(where: {account: "${account}"}) {
          amount
          id
          timestamp
          asset
          account
          trader
        }
      }
    `;
      return getPalmSubgraph(chainId, "stats")
        ?.query({ query })
        .then((response) => response.data.fundedHistoryItems);
    },
  });

  const allTimeCashout = useMemo(() => {
    if (fundedHistoryItems) {
      const total = fundedHistoryItems.reduce((total, current) => {
        return BigNumber.from(current.amount).add(total);
      }, BigNumber.from(0));

      if (total) {
        return formatBigAmount(total, USDC_DECIMALS);
      }

      return "0";
    }

    return "0";
  }, [fundedHistoryItems]);

  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-funded-tabs", LIST_SECTIONS[0]);

  const fundedRankImage = useMemo(
    () => fundedImages[resChallengeNumber ? resChallengeNumber.toNumber() : 0],
    [resChallengeNumber]
  );

  return (
    <div className="tailwind flex items-stretch h-full isolate">
      <div className={cx(["flex-1 px-8 py-[4rem] flex @container/page-content"])}>
        <div className="justify-center flex-1 mx-auto max-w-[114rem] flex flex-col items-stretch min-w-0 pb-[4rem]">
          <div className="stars-bg absolute w-full h-full left-0"></div>
          <BluredEffect />
          <div className="tailwind isolate mb-[3.6rem] flex flex-col items-stretch pt-[9.5rem] z-30">
            <div className="flex flex-col justify-center gap-10 items-start FundedTrading-header">
              <div className="flex flex-col gap-[1rem] w-full items-center text-center FundedTrading-title-wrapper">
                <h1 className="text-[3rem] lg:text-[4.6rem] leading-[1.4] text-[#FECBB6] font-black font-mazzard">
                  <Trans>Start your journey to $250k trades</Trans>
                </h1>

                <a
                  href="https://foxify-1.gitbook.io/foxify-funded-litepaper/"
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  <div className="FundedTrading-docs">
                    <span>
                      <Trans>Read Docs</Trans>
                    </span>
                    <ArrowSmall className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
                  </div>
                </a>
              </div>
            </div>
            <Route exact path={path}>
              {((!isFundedCreated && !isLoading) || (!isActive && !isActivating)) && <Redirect to={`${path}/start`} />}
            </Route>
            <Route path={`${path}/start`}>
              {(listSection === "NFT Staking" || listSection === "Rules") && (
                <FundedTradingStart
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  isActive={isActive}
                  isWhitelisted={isWhitelisted}
                  classNames="mt-[6.7rem]"
                  isCreateFundModalOpen={isCreateFundModalOpen}
                  setIsCreateFundModalOpen={setIsCreateFundModalOpen}
                />
              )}
            </Route>
            <div className="Funded-tabs mt-[4.6rem]">
              <Tab
                options={LIST_SECTIONS}
                optionLabels={LIST_SECTIONS_LABELS}
                option={listSection}
                setOption={setListSection}
                onChange={(section) => setListSection(section)}
                type="table"
                className="h-[5rem] gap-[1rem] md:gap-[0] z-50"
              />
              <Route exact path={path}>
                {isFundedCreated && isActive && (
                  <div className="z-30 mb-[4.6rem]">
                    <ul className="flex flex-col lg:flex-row gap-[2rem]">
                      <li className="Funded-user-data-item longer flex gap-[1rem]">
                        <img
                          src={fundedRankImage}
                          alt="Foxify Rank"
                          width="70"
                          height="70"
                          className="w-[3rem] h-[3rem] md:w-[7rem] md:h-[7rem] col-start-2 col-end-3 justify-self-end row-start-1 lg:row-span-full lg:col-span-1"
                        />
                        <p className="text-inactive text-[1.4rem] self-center col-start-1 lg:col-start-2">
                          <Trans>Your Rank</Trans>
                        </p>
                        <p className="text-[2.4rem] self-center col-start-3 lg:col-start-2 lg:row-start-2">
                          {isLoading ? (
                            <div className="skeleton w-[10rem] h-[2.25rem] rounded"></div>
                          ) : (
                            currentFundedLevel
                          )}
                        </p>
                      </li>
                      <li className="Funded-user-data-item shorter">
                        <p className="text-inactive text-[1.4rem]">
                          <Trans>All time Cashout</Trans>
                        </p>
                        <p className="text-[2.4rem]">
                          {isLoading ? (
                            <div className="skeleton w-[10rem] h-[2.25rem] rounded"></div>
                          ) : (
                            `$${allTimeCashout}`
                          )}
                        </p>
                      </li>
                      <li className="Funded-user-data-item shorter">
                        <p className="text-inactive text-[1.4rem]">
                          <Trans>Rank by Cashout</Trans>
                        </p>
                        <p className="text-[2.4rem]">-</p>
                      </li>
                      <li className="Funded-user-data-item shorter">
                        <p className="text-inactive text-[1.4rem]">
                          <Trans>Your Points</Trans>
                        </p>
                        <p className="text-[2.4rem]">0</p>
                      </li>
                      <li className="Funded-user-data-item shorter">
                        <p className="text-inactive text-[1.4rem]">
                          <Trans>Rank by Points</Trans>
                        </p>
                        <p className="text-[2.4rem]">-</p>
                      </li>
                    </ul>
                  </div>
                )}

                {listSection === "Challenge" && isActive && isFundedCreated && (
                  <ChallengeTab
                    traderContractAddress={traderContractAddress}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    isCreateFundModalOpen={isCreateFundModalOpen}
                  />
                )}
              </Route>

              {listSection === "NFT Staking" && <NFTStakingTab />}
              {listSection === "Leaderboard" && isActive && isFundedCreated && <LeaderBoardTab />}
              {listSection === "Rules" && <RulesTab />}
            </div>
          </div>
          <Route path={`${path}/start`}>
            {!isCreateFundModalOpen && isFundedCreated && !isLoading && <Redirect to={`${path}`} />}
            {(listSection === "Challenge" || listSection === "Leaderboard") && (
              <>
                <FundedTradingBenefits />
                <FundedTradingStart
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  isActive={isActive}
                  isWhitelisted={isWhitelisted}
                  isCreateFundModalOpen={isCreateFundModalOpen}
                  setIsCreateFundModalOpen={setIsCreateFundModalOpen}
                />
              </>
            )}
          </Route>
        </div>
      </div>
    </div>
  );
};

export default Funded;
