const ProgressBar = ({ progress }) => {
  const formattedProgress = progress ? progress : 0;
  return (
    <div className="h-[0.8rem] w-full rounded-full overflow-hidden mt-[1.4rem] bg-green-opacity">
      <div className="h-full rounded-full bg-[var(--pallete-green)]" style={{ width: `${formattedProgress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
