import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

import ModalWithPortal from "components/Modal/ModalWithPortal";
import NftImage from "../../../../img/nft.png";

type MergeNFTModalProps = {
  open: boolean;
  setOpen: (visibleModal: boolean) => void;
  mockNfts: { name: string; img?: string; id?: string }[];
};

export const MergeNFTModal = ({ open, setOpen, mockNfts }: MergeNFTModalProps) => {
  return (
    <ModalWithPortal
      isVisible={open}
      setIsVisible={setOpen}
      label={t`Merge NFTs`}
      showHeaderDivider
      customWidth
      description={t`You may merge 5x Bronze NFTs into 1 Silver NFT.`}
      className="tailwind overflow-y-hidden"
    >
      <div className="flex gap-10 justify-between">
        <div>
          <div className="mb-4 text-[14px]">
            <p>
              <Trans>Select 5 Bronze NFTs</Trans>
            </p>
            <p className="text-[#b3b3b3]">
              <Trans>5/5 Selected</Trans>
            </p>
          </div>
          {mockNfts && (
            <div className="flex flex-wrap justify-center gap-6 overflow-y-auto pb-[40px] max-h-[482px]">
              {mockNfts.map((nft, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center bg-[#101014] rounded-[15px] border border-[rgba(255,255,255,0.08)] p-2 w-[160px] flex-1  max-w-[160px] h-[212px] basis-[calc(33.33%-1rem)]"
                >
                  <img src={nft.img} alt="img2" className="object-cover rounded-[10px] mb-4 w-[150px] h-[150px]" />
                  <div className="flex justify-between w-full items-center">
                    <p className="text-[16px]">{nft.name}</p>
                    <p className="h-[35px] text-[14px] px-[15px] bg-[#202024] overflow-visible content-center flex-nowrap  rounded-[12px]">
                      {nft.id}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          <button
            onClick={() => {}}
            className="Hidden-button hidden text-[14px] text-main px-[1.7rem] py-[1rem] rounded-[1.5rem] Create-funded-btn h-[4.5rem] w-full"
          >
            <Trans>Merge NFTs</Trans>
          </button>
        </div>
        <div className="Hidden-block">
          <div className="mb-4 text-[14px]">
            <p>
              <Trans>You will get</Trans>
            </p>
            <p className="text-[#b3b3b3]">
              <Trans>You will get random silver NFTs</Trans>
            </p>
          </div>

          <div className="flex flex-col justify-between items-center w-[240px] h-[414px] bg-white bg-opacity-5 rounded-[10px] p-[10px]">
            <div className="m-auto">
              <div className="flex flex-col justify-center items-center rounded-[10px] border bg-[#101014] border-[rgba(255,255,255,0.08)] w-fit p-2">
                <img src={NftImage} alt="randomNFT" className="w-[150px] h-[150px]  rounded-[10px] blur-sm mb-4" />
                <p className="text-center text-[14px] py-[10px]">1x Random NFT</p>
              </div>
            </div>

            <button
              onClick={() => {}}
              className="text-main px-[1.7rem] text-[14px] py-[1rem] rounded-[1.5rem] Create-funded-btn h-[4.5rem] w-full"
            >
              <Trans>Merge NFTs</Trans>
            </button>
          </div>
        </div>
      </div>
    </ModalWithPortal>
  );
};
